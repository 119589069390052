<template>
    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
        <div v-if="loading" class="flex justify-center items-center h-64">
            <div class="spinner-wrapper"><div class="spinner"></div></div>
        </div>
                
        <div v-else class="rounded-t mb-0 px-4 py-3 border-0">
            <div class="block w-full overflow-x-auto">
                <!-- Request Details Section -->
                <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-6">
                    <div class="col-span-1">
                        <h3 class="text-xl font-semibold mb-2">Request Details</h3>
                        <div class="bg-gray-100 p-4 rounded">
                            <p><strong>Time:</strong> {{ formattedTime }}</p>
                            <p><strong>Hostname:</strong> {{ headers.host }}</p>
                            <p><strong>Method:</strong> {{ content.method }}</p>
                            <p><strong>Controller Action:</strong> {{ content.controller_action }}</p>
                            <p><strong>Middleware:</strong> {{ Array.isArray(content.middleware) ?
                                content.middleware.join(', ') : 'N/A' }}</p>
                            <p><strong>Path:</strong> {{ content.uri }}</p>
                            <p><strong>Status:</strong> {{ content.response_status }}</p>
                            <p><strong>Duration:</strong> {{ content.duration }} ms</p>
                            <p><strong>IP Address:</strong> {{ content.ip_address }}</p>
                            <p><strong>Memory usage:</strong> {{ content.memory }} MB</p>
                        </div>
                    </div>

                    <div  v-if="user" class="col-span-1 mt-10">
                        <h3 class="text-xl font-semibold mb-2">Authenticate User</h3>
                        <div class="bg-gray-100 p-4 rounded">
                            <p><strong>ID:</strong> {{ user.id }}</p>
                            <p><strong>Email:</strong> {{ user.email }}</p>
                        </div>
                    </div>
                </div>

                <!-- Tabs for Request Details -->
                <div class="rounded-t mb-0 px-4 py-3 border-0 mt-10">
                    <div class="block w-full overflow-x-auto">
                        <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-6">
                            <div class="mb-6">
                                <ul class="flex border-b">
                                    <li @click="activeTab = 'payloads'"
                                        :class="{ 'border-blue-500': activeTab === 'payloads' }"
                                        class="cursor-pointer px-4 py-2">
                                        Payloads
                                    </li>
                                    <li @click="activeTab = 'headers'"
                                        :class="{ 'border-blue-500': activeTab === 'headers' }"
                                        class="cursor-pointer px-4 py-2">
                                        Headers
                                    </li>
                                    <li @click="activeTab = 'session'"
                                        :class="{ 'border-blue-500': activeTab === 'session' }"
                                        class="cursor-pointer px-4 py-2">
                                        Session
                                    </li>
                                    <li @click="activeTab = 'response'"
                                        :class="{ 'border-blue-500': activeTab === 'response' }"
                                        class="cursor-pointer px-4 py-2">
                                        Response
                                    </li>
                                </ul>
                                <div v-if="activeTab === 'payloads'" class="p-4">
                                    <h4 class="text-lg font-semibold">Payloads</h4>
                                    <CodeSnippet :code="formattedPayload" language="json" />
                                </div>
                                <div v-if="activeTab === 'headers'" class="p-4">
                                    <h4 class="text-lg font-semibold">Headers</h4>
                                    <CodeSnippet :code="formattedHeaders" language="json" />
                                </div>
                                <div v-if="activeTab === 'session'" class="p-4">
                                    <h4 class="text-lg font-semibold">Session</h4>
                                    <CodeSnippet :code="formattedSession" language="json" />
                                </div>
                                <div v-if="activeTab === 'response'" class="p-4">
                                    <h4 class="text-lg font-semibold">Response</h4>
                                    <CodeSnippet :code="formattedResponse" language="json" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Additional Tabs Section -->
                <div class="rounded-t mb-0 px-4 py-3 border-0 mt-10">
                    <div class="block w-full overflow-x-auto">
                        <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-6">
                            <div class="mb-6">
                                <ul class="flex border-b">
                                    <li @click="additionalTab = 'queries'"
                                        :class="{ 'border-blue-500': additionalTab === 'queries' }"
                                        class="cursor-pointer px-4 py-2">
                                        Queries
                                    </li>
                                    <li @click="additionalTab = 'models'"
                                        :class="{ 'border-blue-500': additionalTab === 'models' }"
                                        class="cursor-pointer px-4 py-2">
                                        Models
                                    </li>
                                    <li @click="additionalTab = 'caches'"
                                        :class="{ 'border-blue-500': additionalTab === 'caches' }"
                                        class="cursor-pointer px-4 py-2">
                                        Caches
                                    </li>
                                </ul>
                                <div v-if="additionalTab === 'queries'" class="p-4">
                                    <h4 class="text-lg font-semibold">Queries</h4>
                                    <table class="min-w-full w-full divide-y divide-gray-200">
                                        <tbody class="bg-white divide-y divide-gray-200">
                                            <tr v-for="(query, index) in queries" :key="index">
                                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    <pre>{{ formatQuery(query.sql) }}</pre>
                                                </td>
                                                <td
                                                    class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
                                                    <!-- <button @click="toggleQueryDetails(index)" class="text-blue-500 hover:underline">
                                            {{ showFullQueryIndex === index ? 'Show Less' : 'Show More' }}
                                            </button> -->
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div v-if="additionalTab === 'models'" class="p-4">
                                    <h4 class="text-lg font-semibold">Models</h4>
                                    <table class="min-w-full w-full divide-y divide-gray-200">
                                        <tbody class="bg-white divide-y divide-gray-200">
                                            <tr v-for="(model, index) in models" :key="index">
                                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    <pre>{{ model.model }}</pre>
                                                </td>
                                                <td
                                                    class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
                                                    <!-- <button @click="toggleQueryDetails(index)" class="text-blue-500 hover:underline">
                                            {{ showFullQueryIndex === index ? 'Show Less' : 'Show More' }}
                                            </button> -->
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div v-if="additionalTab === 'caches'" class="p-4">
                                    <h4 class="text-lg font-semibold">Caches</h4>
                                    <table class="min-w-full w-full divide-y divide-gray-200">
                                        <tbody class="bg-white divide-y divide-gray-200">
                                            <tr v-for="(cache, index) in caches" :key="index">
                                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    <pre>{{ cache.key }}</pre>
                                                </td>
                                                <td
                                                    class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
                                                    <!-- <button @click="toggleQueryDetails(index)" class="text-blue-500 hover:underline">
                                            {{ showFullQueryIndex === index ? 'Show Less' : 'Show More' }}
                                            </button> -->
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '../../axios';  // Adjust import as needed
import CodeSnippet from '@/components/CodeSnippet/CodeSnippet';

export default {
    data() {
        return {
            loading: false,
            entry: {},
            headers: {},
            content: {},
            user: {},
            queries: {},
            models: {},
            caches: {},
            batch: {},
            activeTab: 'payloads',
            additionalTab: 'queries',
            maxLength: 100,
        };
    },
    computed: {
        formattedTime() {
            // Format the time here, e.g., using moment.js or another date library
            return this.entry.created_at || 'N/A';
        },
        formattedPayload() {
            return JSON.stringify(this.content.payload, null, 2);
        },
        formattedHeaders() {
            return JSON.stringify(this.headers, null, 2);
        },
        formattedSession() {
            return JSON.stringify(this.content.session, null, 2);
        },
        formattedResponse() {
            return JSON.stringify(this.content.response, null, 2);
        },
        
    },
    async created() {
        const requestId = this.$route.params.id;
        await this.fetchRequestDetails(requestId);
    },
    methods: {
        async fetchRequestDetails(requestId) {
            this.loading = true; 
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`/telescope/requests/${requestId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                const data = response.data.data;
                this.entry = data.entry;
                this.headers = data.entry.content.headers;
                this.content = data.entry.content;
                this.user = data.entry.content.user;
                this.queries = data.queries;
                this.models = data.models;
                this.caches = data.caches;
            } catch (error) {
                console.error('Error fetching request details:', error);
                this.loading = false; 
            } finally {
                this.loading = false;
            }
        },

        formatQuery(query) {
            // Truncate query and add ellipsis if it's too long
            return query.length > this.maxLength
            ? query.slice(0, this.maxLength) + '...'
            : query;
        },
    },
    components: {
        CodeSnippet,
    },
};
</script>

<style scoped>
/* Add your styles here */
</style>
