<template>
  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
    :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']">
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-lg" :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']">
            Tenants
          </h3>
        </div>
        <div class="relative w-full px-4 flex-grow flex-1">
          <router-link to="/admin/tenants/add">
            <button
              class="bg-emerald-500 text-white active:bg-emerald-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 float-right"
              type="button">
              Add Tenant
            </button>
          </router-link>
        </div>
        <div class="relative w-full px-4 max-w-full flex-grow mt-4">
          <input v-model="search" @input="fetchTenants" type="text" placeholder="Search by keyword"
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
          
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Tenants table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>

          <tr>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
              ID
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
              STATUS
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
              Name
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
              Email
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
              Domain
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="loading">
            <td colspan="6" class="text-center py-4">
              <div class="spinner-wrapper"><div class="spinner"></div></div>
            </td>
          </tr>
          <tr v-for="tenant in tenants" :key="tenant.id">
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              <span class="font-bold text-blueGray-600">{{ tenant.id }}</span>
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              <i
                :class="tenant.status === 'active' ? 'fas fa-circle text-orange-500 mr-2' : 'fas fa-circle text-emerald-500 mr-2'"></i>
              {{ tenant.status === 'active' ? 'Active' : 'Inactive' }}
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              {{ tenant.name }}
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              {{ tenant.email }}
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              <ul>
                <li v-for="domain in tenant.domains" :key="domain">{{ domain.domain }}</li>
              </ul>
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
              <tenant-table-dropdown :tenantId="tenant.id" :currentStatus="tenant.status"
                @status-updated="handleStatusUpdated" @edit-tenant="handleEditTenant"/>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import axios from '../../axios';
import TenantTableDropdown from "@/components/Tenants/TenantTableDropdown.vue";


export default {
  data() {
    return {
      tenants: [],  // To hold the tenant data
      search: '',   // To hold the search query
      color: 'light',  // Default color
      loading: false  // Loading state
    };
  },
  methods: {
    async fetchTenants() {
      this.loading = true;  // Set loading state to true
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/get_all_tenants', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          params: {
            search: this.search,
          },
        });

        console.log('All tenant data');
        console.log(response.data.data.data);

        this.tenants = response.data.data.data;

       

      } catch (error) {
        console.error('Error fetching tenants:', error);
      } finally {
        this.loading = false;  // Set loading state to false
      }
    },

    handleStatusUpdated(tenantId, newStatus) {
      const tenant = this.tenants.find(t => t.id === tenantId);
      if (tenant) {
        tenant.status = newStatus;
      }
    },

    handleEditTenant(tenantId) {
      this.$router.push(`/admin/tenants/edit/${tenantId}`); // Redirect to the edit page
    },
  },
  created() {
    // Fetch tenants initially when the component is created
    this.fetchTenants();
  },
  components: {
    TenantTableDropdown,
  },
};
</script>
