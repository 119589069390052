<template>
    <pre v-if="language && code" :class="`language-${language}`">
      <code v-html="highlightedCode"></code>
    </pre>
  </template>
  
  <script>
  import hljs from 'highlight.js/lib/core';
  import json from 'highlight.js/lib/languages/json';
  import 'highlight.js/styles/github.css'; // Use your preferred style
  
  hljs.registerLanguage('json', json);
  
  export default {
    props: {
      code: {
        type: String,
        required: true,
      },
      language: {
        type: String,
        default: 'json',
      },
    },
    computed: {
      highlightedCode() {
        return hljs.highlight(this.code, { language: this.language }).value;
      },
    },
  };
  </script>
  
  <style scoped>
  /* Additional styles for code snippet display */
  pre {
    background: #f5f5f5;
    border: 1px solid #ddd;
    padding: 1em;
    border-radius: 4px;
    overflow: auto;
  }
  code {
    font-family: 'Courier New', Courier, monospace;
  }
  </style>
  