<template>
  <!-- Edit Tenant Form -->
  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Edit Tenant</h6>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <div v-if="loading" class="spinner-wrapper">
        <div class="spinner"></div>
      </div>
      <form v-else @submit.prevent="updateTenant">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Tenant Information
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="tenant-name">
                Name
              </label>
              <input v-model="tenant.name" type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                id="tenant-name" placeholder="Tenant Name" required />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="tenant-domain">
                Domain Name
              </label>
              <input v-model="tenant.domain_name" type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                id="tenant-domain" placeholder="Tenant Domain" required />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="tenant-email">
                Email address
              </label>
              <input v-model="tenant.email" type="email"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                id="tenant-email" placeholder="Tenant Email" required />
            </div>
          </div>
        </div>

        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Password
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="tenant-password">
                Password
              </label>
              <input v-model="tenant.password" type="password"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                id="tenant-password" placeholder="Tenant Password" />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="tenant-confirm-password">
                Confirm Password
              </label>
              <input v-model="tenant.confirm_password" type="password"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                id="tenant-confirm-password" placeholder="Confirm Password" />
            </div>
          </div>
        </div>

        <div class="text-center mt-6">
          <button
            class="bg-emerald-500 text-white active:bg-emerald-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="submit">
            Update Tenant
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from '../../../axios';

export default {
  data() {
    return {
      tenant: {
        id: '',
        name: '',
        email: '',
        domain_name: '',
        password: '',
        confirm_password: '',
      },
      loading: false,
    };
  },
  created() {
    this.fetchTenant();
  },
  methods: {
    async fetchTenant() {
      this.loading = true;
      const tenantId = this.$route.params.id;
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`/tenants/${tenantId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        const { name, email, domain_name } = response.data.data; // Destructure response data
        this.tenant = { id: tenantId, name, email, domain_name, password: '', confirm_password: '' }; // Clear passwords
      } catch (error) {
        console.error('Error fetching tenant:', error);
      } finally {
        this.loading = false;
      }
    },
    async updateTenant() {
      this.loading = true;
      const tenantId = this.$route.params.id;
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post(`/update_tenant_by_id`, {
            ...this.tenant,
            id: tenantId // Include the ID in the payload
          }, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        console.log('Tenant updated successfully:', response.data);
        this.$router.push('/admin/tenants'); // Redirect to tenants list page
      } catch (error) {
        console.error('Error updating tenant:', error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>