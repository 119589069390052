<template>
  <div class="flex flex-wrap mt-4">
    <div class="w-full mb-12 px-4">
      <tenant-table />
    </div>
  </div>
</template>
<script>
import TenantTable from "@/components/Tenants/TenantTable";

export default {
  components: {
    TenantTable,
  },
};
</script>
