<template>
  <div>
    <a class="text-blueGray-500 py-1 px-3" href="#pablo" ref="btnDropdownRef" v-on:click="toggleDropdown($event)">
      <i class="fas fa-ellipsis-v"></i>
    </a>
    <div ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48" v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }">

      <!-- Edit Link -->
      <a href="#" v-on:click.prevent="editTenant"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700">
        Edit
      </a>

      <!-- Status Link with Loader -->
      <div class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700">
        <a v-if="!loading" href="javascript:void(0);" v-on:click="updateStatus">
          {{ currentStatus === 'active' ? 'Inactive' : 'Active' }}
        </a>
        <div v-else class="drop-down-spinner-wrapper">
          <div class="drop-down-spinner"></div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import axios from "../../axios"; // Adjust the path if necessary

export default {
  props: {
    tenantId: {
      type: Number,
      required: true,
    },
    currentStatus: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dropdownPopoverShow: false,
      loading: false,
    };
  },
  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      this.dropdownPopoverShow = !this.dropdownPopoverShow;
      if (this.dropdownPopoverShow) {
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
    async updateStatus() {
      this.loading = true;
      try {
        const token = localStorage.getItem('token');
        const newStatus = this.currentStatus === 'active' ? 'inactive' : 'active';
        
        const response = await axios.post('/update_tenant_status', {
          tenant_id: this.tenantId,
          status: newStatus
        }, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        this.$emit('status-updated', this.tenantId, newStatus);
        console.log('Status updated successfully:', response.data);
      } catch (error) {
        console.error('Error updating status:', error);
      } finally {
        this.loading = false;
        this.dropdownPopoverShow = false;
      }
    },

    editTenant() {
      // Emit an event to navigate to the edit page
      this.$emit('edit-tenant', this.tenantId);
      this.dropdownPopoverShow = false; // Close dropdown after action
    },
  },
};
</script>
